<template>
  <div class="sidebarBox">
    <div class="listBox">
      <div class="course">课程中心</div>
      <ul class="column">
        <li
          v-for="(item, index) in listSide"
          @click="go(`${item.path}`,index)"
          :class="activity==index?'activity':'default'"
          :key="index"
        >{{item.text}}</li>
      </ul>
      <div class="makeBtn" @click="go('consult')">预约服务</div>
      <div class="journalismTitle">
        <div>新闻中心</div>
        <p @click="moreNews">MORE</p>
      </div>
       <div class="journalismBox" v-for="(item, index) in newsList" :key="index" @click="goparticulars(item)">
          <img class="logo" :src="item.mainPicRealPath" alt />
          <div class="journalismText">
            <h3>{{item.title}}</h3>
            <span>{{item.assistantTitle}}</span>
            <div class="time">{{item.publishDate}} <span class="el-icon-view"> {{item.view}}</span></div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { homeService } from "@/api"
import moment from "moment"
export default {
  data() {
    return {
      listSide: [
        {
          path: "/attestation",
          text: "认证课程",
        },
        {
          path: "/attestationFingerpost?index=1",
          text: "认证指南",
        },
        {
          path: "/attestationFingerpost?index=2",
          text: "证书介绍",
        },
        {
          path: "/companySynopsis",
          text: "了解更多",
        },
      ],
      activity:sessionStorage.getItem("sidebarIndex"),
      newsList: []
    };
  },
   watch: {
    $route(){
      this.activity =  sessionStorage.getItem("sidebarIndex")
    }
  },
  methods: {
    goparticulars(item) {
      let detailId= item.id + ".html"
      this.$router.push({name: "particulars",params:{ id: detailId, ifjournalism: 2}})
    },
    go(path,index) {
      this.$router.push(path);
      sessionStorage.setItem('sidebarIndex',index)
    },
    getNewsList() {
      let data = {
        type: 1,
        pageNum: 1,
        pageSize: 3
      }
      homeService.getNewsList(data).then(res => {
        res.list.forEach(ele => {
            ele.publishDate = moment(ele.publishDate).format("YYYY-MM-DD")
            ele.content = ele.content.replace(/<img\b[^>]*>/, "");  
        });
        this.newsList = res.list
      })
    },
    moreNews() {
      this.$router.push('/journalism');
    }
  },
  created() {
    this.getNewsList()
  }
};
</script>
<style lang="scss" scoped>
.sidebarBox {
  float: right;
  width: 20%;
  .listBox {
    margin-top: 30px;
    .course {
      font-size: 16px;
      color: #ffffff;
      text-indent: 20px;
      background-color: rgb(153, 153, 153);
      padding: 15px 0;
    }
    .column {
      overflow: hidden;
      font-size: 14px;
      li{
        cursor: pointer;
      }
      .default {
        background: linear-gradient(#ffffff, #cccccc);
        height: 40px;
        line-height: 40px;
        text-align: center;
      }
      .activity{
        background: linear-gradient(#ffffff, #999);
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
      }
    }
    .makeBtn {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: linear-gradient(rgb(79, 167, 218), rgb(7, 56, 79));
      border-radius: 10px;
      margin: 20px 0;
      color: #ffffff;
      cursor: pointer;
    }
    .journalismTitle {
      overflow: hidden;
      height: 45px;
      line-height: 45px;
      background-color: rgb(153, 153, 153);
      color: #fff;
      font-size: 14px;
      div {
        float: left;
        height: 45px;
        text-indent: 10px;
      }
      p {
        height: 45px;
        float: right;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .journalismBox {
      overflow: hidden;
      border-bottom: rgb(232, 232, 232) 1px solid;
      margin: 5px 0;
      cursor: pointer;
      .logo {
        background-color: rosybrown;
        height: 40px;
        width: 50px;
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-top: 10px;
      }
      .journalismText {
        float: left;
        width: 160px;
        margin-left: 10px;
        h3 {
          width: 90%;
          margin: 5px 0;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        span {
          overflow: hidden;
          color: #999;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .time {
          color: rgb(214, 214, 206);
          padding: 2px 0;
          span{
            float: right;
          }
        }
      }
    }
  }
}
</style>