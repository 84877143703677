<template>
  <div>
    <div class="textTitle">
      <p>{{text}}</p>
      <div class="w_line"></div>
    </div>
  </div>
</template>
<script>
    export default {
        props:{
            text:String
        }
    }
 </script>

<style lang="scss" scoped>
.textTitle {
  margin-top: 50px;
  overflow: hidden;
  p {
    font-size: 24px;
    font-weight: bold;
  }
  .w_line {
    width: 60px;
    height: 2px;
    background-color: #000;
    margin: 20px 0;
  }
}
</style>