import axios from "axios"
import { Message } from "element-ui"


const service = axios.create({
    baseURL: '/C/',
    //baseURL: 'https://www.yjyculture.com/C/',
    timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        // Do something with request error
        console.log("interceptors.request.use  receive error")
        console.log(error) // for debug
        Promise.reject(error)
    }
)

// respone拦截器
service.interceptors.response.use(
    response => {
        /**
         * code为非200是抛错 可结合自己业务进行修改
         */
        const res = response.data
        if (res.code === 200) {
            return res.data
        } else {
            Message({
                message: '异常',
                type: "error",
                duration: 2 * 1000
            })
            return Promise.reject(res)
        }
    },
    error => {
            Message({
                message: '参数异常',
                type: "error",
                duration: 5 * 1000
            })
            return Promise.reject({
                data: error,
                message: '参数异常'
            })
    }
)

export default service
