import request from "../request.js"

var services = {
    /* 获取轮播图 */
    getBannerList(params) {
        return request({
            url: "/yjyBanner/list",
            method: "get",
            params: {
                pageNum: params.pageNum,
                pageSize: params.pageSize
            }
        })
    },
    /* 合作伙伴 */
    getPartnersList(params) {
        return request({
            url: "/yjyPartners/list",
            method: "get",
            params: {
                pageNum: params.pageNum,
                pageSize: params.pageSize
            }
        })
    },
    /* 新闻列表 */
    getNewsList(params) {
        return request({
            url: "/yjyNews/list",
            method: "get",
            params: {
                pageNum: params.pageNum,
                pageSize: params.pageSize,
                type: params.type
            }
        })
    },
    /* 新闻列表详情 */
    getNewsDetail(id) {
        return request({
            url: "/yjyNews/findById",
            method: "get",
            params: {
                id: id
            }
        })
    },
    /* 获取浏览次数 */
    /* getView(params) {
        let url = params.tag === 'news' ? '/yjyNews/viewById' : ''
        return request({
            url: url,
            method: "get",
            params: {
                id: params.id
            }
        })
    }, */
    /* 首页留言 */
    addConsumerInto(data) {
        return request({
            url: "/yjyConsumerInto/add",
            method: "post",
            data: {
                businessName: data.businessName,
                liaisonMan: data.liaisonMan,
                liaisonPhone: data.liaisonPhone
            }
        })
    },
    /* 我要应聘 */
    addCandidates(data) {
        return request({
            url: "/yjyCandidates/add",
            method: "post",
            data: {
                liaisonEmail: data.liaisonEmail,
                liaisonMan: data.liaisonMan,
                liaisonPhone: data.liaisonPhone,
                explains: data.explains
            }
        })
    }
}

export default services
